// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

export interface TextEncoder {
  convert(input: string): Uint8Array;
}

export const textEncoder: TextEncoder = {
  convert(input) {
    return new TextEncoder().encode(input);
  },
};
