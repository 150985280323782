import * as Sentry from '@sentry/react';
import React, { lazy, Suspense, useEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { fetchGet } from './api/common';
import { User } from './api/queries/user/domain';

import { ContentSpinner, SuspenseSpinner } from './components/common/Spinner';
import ErrorBoundaryView from './components/error/ErrorBoundaryView';
import config from './config';
import PublicRoutes from './routes/PublicRoutes';
import lazyRetry from './utils/lazyRetry';
import dayjs from 'dayjs';
import 'dayjs/locale/nb';
import 'dayjs/locale/es';
import 'dayjs/locale/en';
import { useTranslation } from 'react-i18next';

const BoligselskapProvider = lazy(() => lazyRetry(() => import('./layouts/BoligselskapProvider')));
const ThemeProvider = lazy(() => lazyRetry(() => import('./layouts/ThemeProvider')));

const AdminLayout = lazy(() => lazyRetry(() => import('./layouts/AdminLayout')));
const ForvaltningLayout = lazy(() => lazyRetry(() => import('./layouts/ForvaltningLayout')));
const OpenLayout = lazy(() => lazyRetry(() => import('./layouts/OpenLayout')));
const SecureLayout = lazy(() => lazyRetry(() => import('./layouts/SecureLayout')));
const StyreportalLayout = lazy(() => lazyRetry(() => import('./layouts/StyreportalLayout')));

const AdminRoutes = lazy(() => lazyRetry(() => import('./routes/AdminRoutes')));
const ForvaltningRoutes = lazy(() => lazyRetry(() => import('./routes/ForvaltningRoutes')));
const LegacyBoligselskapReroute = lazy(() =>
  lazyRetry(() => import('./routes/helpers/LegacyBoligselskapReroute'))
);
const MultiOrSingleSelskapIndexElement = lazy(
  () => import('./routes/MultiOrSingleSelskapIndexElement')
);
const Profile = lazy(() => lazyRetry(() => import('./containers/Profile')));
const SelectBoligselskap = lazy(() => lazyRetry(() => import('./containers/SelectBoligselskap')));
const StyreportalRoutes = lazy(() => lazyRetry(() => import('./routes/StyreportalRoutes')));

const CognitoLogin = lazy(() => lazyRetry(() => import('./containers/CognitoLogin')));
const CognitoLogout = lazy(() => lazyRetry(() => import('./containers/CognitoLogout')));
const CognitoToken = lazy(() => lazyRetry(() => import('./containers/CognitoToken')));
const DevCognitoPathsEnabledRoute = lazy(() =>
  lazyRetry(() => import('./routes/DevCognitoPathsEnabledRoute'))
);
const Auth = lazy(() => lazyRetry(() => import('./containers/Auth')));

const helmetContext = {};

const updateLocale = require('dayjs/plugin/updateLocale');
dayjs.extend(updateLocale);

const App = () => {
  const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loadingUser, setLoadingUser] = useState<boolean>(false);
  const { i18n } = useTranslation();

  useEffect(() => {
    const i18nLanguage = i18n.language;
    dayjs.locale(i18nLanguage);

    const languageMatch: { [key: number]: { lang: string } } = {
      160: { lang: 'nb' },
      199: { lang: 'es' },
      225: { lang: 'en' },
    };

    if (user?.preferredLanguage && languageMatch[user.preferredLanguage]) {
      const preferredLang = languageMatch[user.preferredLanguage].lang;
      if (i18nLanguage !== preferredLang) {
        i18n.changeLanguage(preferredLang);
      }
    }
  }, [i18n, user]);

  useEffect(() => {
    if (!loadingUser && user === undefined) {
      setLoadingUser(true);
      fetchGet('/api/user')
        .then((res) => {
          if (!res) {
            setUser({
              username: 'ANONYMOUS',
              id: -1,
              personId: -1,
              epost: 'tore@incogito.no',
              etternavn: 'Incognito',
              fornavn: 'Tore',
              roles: [],
            });
            Sentry.setUser({
              email: 'E-post',
              name: `Navn`,
            });
          } else {
            setUser(res);
            Sentry.setUser({
              id: res.personId,
              username: res.id,
              email: res.epost === 'anon@solibo.io' ? 'E-post' : res?.epost,
              name: res.fornavn === undefined ? 'Navn' : `${res.fornavn} ${res.etternavn}`,
            });
          }
        })
        .catch((e) => {
          console.error(e);
          setUser({
            username: 'ANONYMOUS',
            id: -1,
            personId: -1,
            epost: 'tore@incogito.no',
            etternavn: 'Incognito',
            fornavn: 'Tore',
            roles: [],
          });
          Sentry.setUser({
            email: 'E-post',
            name: `Navn`,
          });
        })
        .finally(async () => {
          setLoadingUser(false);
        });
    }
  }, [loadingUser, user]);

  useEffect(() => {
    const resetHeight = () => {
      document.body.style.height = window.innerHeight + 'px';
    };

    window.addEventListener('resize', resetHeight);
    resetHeight();
    return () => window.removeEventListener('resize', resetHeight);
  }, []);

  return (
    <Sentry.ErrorBoundary
      showDialog={false}
      fallback={({ resetError }) => <ErrorBoundaryView resetError={resetError} />}
    >
      <BrowserRouter>
        <Suspense fallback={<SuspenseSpinner />}>
          <SentryRoutes>
            <Route path='auth/*' element={<Auth />} />
            <Route
              path='secure'
              element={
                <ThemeProvider>
                  <BoligselskapProvider>
                    <SecureLayout />
                  </BoligselskapProvider>
                </ThemeProvider>
              }
            >
              <Route path='admin/*' element={<AdminLayout />}>
                <Route
                  path='*'
                  element={
                    <Suspense fallback={<ContentSpinner />}>
                      <AdminRoutes />
                    </Suspense>
                  }
                />
              </Route>
              <Route path='boligselskap/*' element={<LegacyBoligselskapReroute />} />
              <Route path={`styreportal`} element={<StyreportalLayout />}>
                <Route index element={<MultiOrSingleSelskapIndexElement />} />
                <Route
                  path={config.multiBoligselskapPrefix}
                  element={
                    <Suspense fallback={<ContentSpinner />}>
                      <SelectBoligselskap />
                    </Suspense>
                  }
                />
                <Route
                  path=':boligselskapId/*'
                  element={
                    <Suspense fallback={<ContentSpinner />}>
                      <StyreportalRoutes />
                    </Suspense>
                  }
                />
                <Route path='profil' element={<Profile />} />
              </Route>
              <Route path={`forvaltning`} element={<ForvaltningLayout />}>
                <Route index element={<MultiOrSingleSelskapIndexElement />} />
                <Route
                  path={config.multiBoligselskapPrefix}
                  element={
                    <Suspense fallback={<ContentSpinner />}>
                      <SelectBoligselskap />
                    </Suspense>
                  }
                />
                <Route
                  path=':boligselskapId/*'
                  element={
                    <Suspense fallback={<ContentSpinner />}>
                      <ForvaltningRoutes />
                    </Suspense>
                  }
                />
                <Route path='profil' element={<Profile />} />
              </Route>
            </Route>
            <Route
              path={`/${config.hjemmesidePrefix}/*`}
              element={
                <Suspense fallback={<ContentSpinner />}>
                  <OpenLayout />
                </Suspense>
              }
            />
            <Route
              path='/*'
              element={
                //@ts-ignore
                <HelmetProvider context={helmetContext}>
                  <PublicRoutes />
                </HelmetProvider>
              }
            />
            <Route
              element={
                <Suspense fallback={<ContentSpinner />}>
                  <DevCognitoPathsEnabledRoute />
                </Suspense>
              }
            >
              <Route path='/cognitoToken' element={<CognitoToken />} />
              <Route path='/cognitoLogout' element={<CognitoLogout />} />
              <Route path='/cognitoLogin' element={<CognitoLogin />} />
            </Route>
          </SentryRoutes>
        </Suspense>
      </BrowserRouter>
      <ToastContainer />
    </Sentry.ErrorBoundary>
  );
};

export default App;
