import classNames from 'classnames';
import React, { createContext, useContext, useEffect } from 'react';
import { QueryClient, QueryClientProvider, setLogger } from 'react-query';
import { useLocation } from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'react-use';

import '../utils/i18n';

import { isLegacyUrl } from '../routes/helpers/handleLegacyUrl';
import config from '../config';

const themes = ['styreportal', 'admin', 'forvaltning'] as const;

export type ThemeT = (typeof themes)[number];

function isTheme(str: string): str is ThemeT {
  return str === 'styreportal' || str === 'admin' || str === 'forvaltning';
}

type ThemeContextType = {
  theme?: ThemeT;
};

export const ThemeContext = createContext<ThemeContextType>({
  theme: 'styreportal',
});

interface ThemeProviderProps {
  children: React.ReactNode;
}

setLogger(config.logger);

export const useTheme = () => useContext(ThemeContext);
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 10 * (60 * 1000), // 10 mins
      cacheTime: 15 * (60 * 1000), // 15 mins
    },
  },
});

const ThemeProvider = ({ children }: ThemeProviderProps) => {
  const [theme, setTheme] = useSessionStorage<ThemeT | undefined>('currentTheme');
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const defaultContext = {
    theme,
    setTheme,
  };

  useEffect(() => {
    const found = pathname
      .toString()
      .split('/')
      .find((str) => isTheme(str));
    if (Boolean(found)) {
      setTheme(found as ThemeT);
    } else {
      setTheme(themes[0]);

      if (!isLegacyUrl(pathname)) {
        navigate(themes[0]);
      }
    }
  }, [navigate, pathname, setTheme]);

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeContext.Provider value={defaultContext}>
        <div
          className={classNames('h-full w-full', {
            'theme-styreportal': theme === 'styreportal',
            'theme-admin': theme === 'admin',
            'theme-forvaltning': theme === 'forvaltning',
          })}
        >
          {children}
        </div>
      </ThemeContext.Provider>
    </QueryClientProvider>
  );
};

export default ThemeProvider;
