import { fetchPost } from '../../api/common';

export enum SubscriptionType {
  NEWSLETTER,
  CONTACT_SALES,
  TRY_FREE,
  ORDER,
  OKONOMI,
}

export const subscribe = ({
  subscriptionType,
  formData,
}: {
  subscriptionType: SubscriptionType;
  formData: FormData;
}): Promise<any> => {
  switch (subscriptionType) {
    case SubscriptionType.NEWSLETTER:
      return fetchPost('/api/marketing/newsletter', {
        email: formData.get('email'),
        boligselskap: formData.get('boligselskap'),
      });
    case SubscriptionType.CONTACT_SALES:
      return fetchPost('/api/marketing/contact/sales', {
        name: formData.get('name'),
        email: formData.get('email'),
        mobile: formData.get('mobile'),
        message: formData.get('message'),
      });
    case SubscriptionType.TRY_FREE:
      return fetchPost('/api/marketing/contact/tryFree', {
        boligselskap: formData.get('boligselskap'),
        email: formData.get('email'),
      });
    case SubscriptionType.ORDER:
      return fetchPost('/api/marketing/contact/order', {
        orderType: formData.get('orderType'),
        boligselskap: formData.get('boligselskap'),
        email: formData.get('email'),
      });
    case SubscriptionType.OKONOMI:
      return fetchPost(
        `/api/marketing/contact/okonomi?boligselskapId=${formData.get('boligselskapId')}`
      );
    default:
      return Promise.reject('No such operation!');
  }
};
