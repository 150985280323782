import { Person } from './Person';
import { IdType, ISODateTimeString, Kommentar, UrlType } from './common';

export interface LeverandorForBoligselskap {
  id: IdType;
  organizationId: IdType;
  leverandorId: IdType;
  boligselskapId: IdType;
  orgnr: string;
  navn: string;
  logoUrl?: UrlType;
  globaleTjenestekategorier: string[];
  tjenestekategorier: string[];
  opprettetAvId: IdType;
  opprettetAv: Person;
  opprettetTidspunkt: ISODateTimeString;
  kommentarer: Leverandorkommentar[];
}

export interface Leverandorkommentar extends Kommentar {}

export interface Leverandorkontakt {
  boligselskapId?: IdType;
  email?: string;
  familyName?: string;
  givenName?: string;
  id: IdType;
  leverandorId: IdType;
  mobile?: string;
  title?: string;
  logoUrl?: string;
}

export const isLeverandorContact = (data: any): data is Leverandorkontakt => {
  return 'leverandorId' in data;
};

export const tjenestekategorier = (leverandor: LeverandorForBoligselskap) =>
  leverandor.globaleTjenestekategorier.concat(leverandor.tjenestekategorier);
