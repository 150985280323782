import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import React from 'react';

import Placeholder48 from '../../images/48w/placeholder.webp';
import Placeholder320 from '../../images/320w/placeholder.webp';
import Placeholder640 from '../../images/640w/placeholder.webp';
import Placeholder980 from '../../images/980w/placeholder.webp';
import ProgressiveImage from '../common/ProgressiveImage';

// noinspection JSUnusedLocalSymbols
const ErrorBoundaryView = ({ resetError }: { resetError: () => void }) => (
  <div className={`flex h-full flex-col items-center justify-center sm:items-end`}>
    <ProgressiveImage
      placeholderSrc={Placeholder48}
      src={Placeholder980}
      srcSet={`${Placeholder320} 320w, ${Placeholder640} 640w, ${Placeholder980} 980w`}
      sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
      alt='banner'
      fit='object-fill h-full'
    />
    <div className='absolute z-10 flex flex-col items-center p-6 sm:-mt-20 sm:w-2/3'>
      <div className='mx-auto mb-6 flex size-40 items-center justify-center rounded-full bg-gray-200'>
        <ExclamationCircleIcon className='size-32 text-red-400' aria-hidden={true} />
      </div>
      <p className='mb-3 text-center text-3xl font-semibold text-gray-600 sm:text-gray-700'>
        Beklager, noe gikk galt.
      </p>
    </div>
  </div>
);

export default ErrorBoundaryView;
