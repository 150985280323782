import { lazy, useState } from 'react';

import Styrearbeid48 from '../../images/48w/Styrearbeid.webp';
import Styrearbeid320 from '../../images/320w/Styrearbeid.webp';
import Styrearbeid640 from '../../images/640w/Styrearbeid.webp';
import Styrearbeid980 from '../../images/980w/Styrearbeid.webp';
import lazyRetry from '../../utils/lazyRetry';
import ProgressiveImage from '../common/ProgressiveImage';

const TrialSignup = lazy(() => lazyRetry(() => import('../common/TrialSignup')));

export const Hero = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible && (
        <TrialSignup modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
      <main className='relative'>
        <div className='mx-auto w-full max-w-7xl pb-20 pt-16 text-center lg:py-48 lg:text-left'>
          <div className='px-4 sm:px-8 lg:w-1/2 xl:pr-16'>
            <h1 className='text-4xl font-extrabold tracking-tight sm:text-5xl md:text-6xl lg:text-5xl'>
              <span className='block xl:inline'>Den beste styreportalen for</span>{' '}
              <span className='block text-emerald-600 xl:inline'>borettslag og sameier</span>
            </h1>
            <p className='mx-auto mt-3 max-w-md text-lg text-gray-700 sm:text-xl md:mt-5 md:max-w-3xl'>
              Gjør livet enklere for deg og ditt styre, ved å bruke det beste verktøyet for
              styrearbeid i borettslag og sameier på markedet.
            </p>
            <div className='mt-10 sm:flex sm:justify-center lg:justify-start'>
              <div className='rounded-md shadow'>
                <button
                  data-cy={'hero-try-free-button'}
                  onClick={() => setModalVisible(true)}
                  className='focus:focus-ring flex w-full cursor-pointer items-center justify-center rounded-md border border-transparent bg-indigo-600 px-8 py-3 text-base font-medium text-white hover:bg-indigo-700 md:px-10 md:py-4 md:text-lg'
                >
                  Bruk gratis
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className='relative mr-4 h-64 w-full overflow-hidden sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2'>
          <ProgressiveImage
            placeholderSrc={Styrearbeid48}
            src={Styrearbeid980}
            srcSet={`${Styrearbeid320} 320w, ${Styrearbeid640} 640w, ${Styrearbeid980} 980w`}
            sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
            alt=''
            fit='object-fit'
          />
        </div>
      </main>
    </>
  );
};
