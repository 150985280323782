import { useEffect, useState } from 'react';
import useNativeLazyLoading from '@charlietango/use-native-lazy-loading';
import classNames from '../../utils/classNames';

const ProgressiveImage = ({
  placeholderSrc,
  src,
  srcSet,
  sizes,
  alt,
  fit,
  rounded,
}: {
  placeholderSrc: string;
  src: string;
  srcSet?: string;
  sizes?: string;
  alt: string;
  fit?: string;
  rounded?: boolean;
}) => {
  const supportsLazyLoading = useNativeLazyLoading();
  const [imageSrc, setImageSrc] = useState(placeholderSrc);
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const img = new Image();
    img.src = src;
    img.srcset = srcSet ?? src;
    img.onload = () => {
      setImageSrc(src);
      setImageLoaded(true);
    };
  }, [src, srcSet]);

  return (
    <div className={classNames('h-full w-full flex items-center justify-center')}>
      <img
        className={classNames(
          fit ? fit : 'object-contain',
          `rounded-md w-screen ${imageLoaded ? '' : 'blur'}`
        )}
        src={imageSrc}
        srcSet={srcSet}
        sizes={sizes}
        alt={alt}
        loading={supportsLazyLoading ? 'lazy' : undefined}
      />
    </div>
  );
};

export default ProgressiveImage;
