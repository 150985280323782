import { useLocation } from 'react-router-dom';

import '../utils/i18n';

import { SuspenseSpinner } from '../components/common/Spinner';
import SoliboHomePage from '../components/landingpage/SoliboHomePage';

const PublicRoutes = () => {
  const location = useLocation();
  if (location.pathname.includes('cognito')) return <SuspenseSpinner />;
  if (location.pathname.includes('secure')) return null;

  return <SoliboHomePage />;
};

export default PublicRoutes;
