import React, { Suspense, lazy } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';

import { SuspenseSpinner } from '../components/common/Spinner';
import lazyRetry from '../utils/lazyRetry';

const Blog = lazy(() => lazyRetry(() => import('../components/landingpage/Blog')));
const Contact = lazy(() => lazyRetry(() => import('../containers/Contact')));
const FAQ = lazy(() => lazyRetry(() => import('../components/landingpage/FAQ')));
const Integrations = lazy(() => lazyRetry(() => import('../components/landingpage/Integrations')));
const Jobs = lazy(() => lazyRetry(() => import('../components/landingpage/Jobs')));
const LandingpageAlleLosninger = lazy(
  () => import('../components/landingpage/LandingpageAlleLosninger')
);
const LandingpageHjemmeside = lazy(() =>
  lazyRetry(() => import('../components/landingpage/LandingpageHjemmeside'))
);
const LandingpageKommunikasjon = lazy(
  () => import('../components/landingpage/LandingpageKommunikasjon')
);
const LandingpageOkonomi = lazy(() =>
  lazyRetry(() => import('../components/landingpage/LandingpageOkonomi'))
);
const LandingpageStyrearbeid = lazy(
  () => import('../components/landingpage/LandingpageStyrearbeid')
);
const Partnerships = lazy(() => lazyRetry(() => import('../components/landingpage/Partnerships')));
const PricingThreeAlternatives = lazy(
  () => import('../components/landingpage/PricingThreeAlternatives')
);
const NotFound = lazy(() => lazyRetry(() => import('../containers/NotFound')));

const PublicLayout = () => {
  const location = useLocation();

  if (location.pathname.includes('secure')) return null;
  if (location.pathname.includes('cognito')) return <SuspenseSpinner />;

  return (
    <>
      <div className='min-h-full'>
        <Suspense fallback={<SuspenseSpinner />}>
          <Routes>
            <Route path='/priser' element={<PricingThreeAlternatives />} />
            <Route path='/blogg/*' element={<Blog />} />
            <Route path='/kontakt' element={<Contact />} />
            <Route path='/styrearbeid' element={<LandingpageStyrearbeid />} />
            <Route path='/hjemmeside' element={<LandingpageHjemmeside />} />
            <Route path='/kommunikasjon' element={<LandingpageKommunikasjon />} />
            <Route path='/okonomi' element={<LandingpageOkonomi />} />
            <Route path='/integrasjoner' element={<Integrations />} />
            <Route path='/partnerskap' element={<Partnerships />} />
            <Route path='/alle' element={<LandingpageAlleLosninger />} />
            <Route path='/hjelpesenter' element={<FAQ />} />
            <Route path='/jobb/*' element={<Jobs />} />
            <Route path='*' element={<NotFound />} />
          </Routes>
        </Suspense>
      </div>
    </>
  );
};

export default PublicLayout;
