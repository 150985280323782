import { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import PublicLayout from '../../layouts/PublicLayout';

import { SuspenseSpinner } from '../common/Spinner';
import { CTASimple, CTAWithFeature } from './CTA';
import { FeaturesSummary, FeaturesTopTwo } from './Features';
import { Footer } from './Footer';
import { Header } from './Header';
import { Hero } from './Hero';
import MobileFriendly from './MobileFriendly';
import { NewsletterWhiteBG } from './Newsletter';
import ScrollToTop from './ScrollToTop';
import { Testimonial } from './Testimonial';

const SoliboHomePage = () => {
  const location = useLocation();

  if (location.pathname.includes('secure') || location.pathname.includes('hp')) return null;

  return (
    <>
      <Helmet>
        <title>Styreportal for borettslag og sameier</title>
        <meta name='description' content='Den beste styreportalen for borettslag og sameier.' />
      </Helmet>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route
          path='/'
          element={
            <>
              <Hero />
              <FeaturesTopTwo />
              <MobileFriendly />
              <CTAWithFeature />
              <FeaturesSummary />
              <CTASimple />
              <Testimonial />
              <NewsletterWhiteBG />
            </>
          }
        />
        <Route path='/styreportal' element={<Navigate to='/' replace={true} />} />
        <Route
          path='/*'
          element={
            <Suspense fallback={<SuspenseSpinner />}>
              <PublicLayout />
            </Suspense>
          }
        />
      </Routes>
      <Footer />
    </>
  );
};

export default SoliboHomePage;
