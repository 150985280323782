import React from 'react';

import Brukervennlighet48 from '../../images/48w/brukervennlighet.webp';
import Brukervennlighet320 from '../../images/320w/brukervennlighet.webp';
import Brukervennlighet640 from '../../images/640w/brukervennlighet.webp';
import Brukervennlighet980 from '../../images/980w/brukervennlighet.webp';
import ProgressiveImage from '../common/ProgressiveImage';

const MobileFriendly = () => (
  <>
    <div className='relative overflow-hidden bg-white'>
      <div className='mx-auto max-w-md px-4 text-center sm:max-w-3xl sm:px-10 lg:max-w-7xl lg:px-8'>
        <div>
          <h2 className='text-base font-semibold uppercase tracking-wider text-indigo-600'>
            Responsivitet
          </h2>
          <p className='mt-2 text-3xl font-extrabold tracking-tight'>
            Tilpasset alle skjermstørrelser
          </p>
          <p className='mx-auto mt-5 max-w-prose text-lg text-gray-700'>
            Uansett om du gjør mesteparten av styrearbeidet foran PC-en eller i farten på mobilen,
            er Solibo Home et godt verktøy. Vi har tilpasset hele plattformen, slik at du får gjort
            det du skal, uansett når og hvor det passer deg best - uten at det går på bekostning av
            brukeropplevelsen.
          </p>
        </div>
        <div className='my-10 md:m-10'>
          <ProgressiveImage
            placeholderSrc={Brukervennlighet48}
            src={Brukervennlighet980}
            srcSet={`${Brukervennlighet320} 320w, ${Brukervennlighet640} 640w, ${Brukervennlighet980} 980w`}
            sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
            alt='Beboermodulen vist på PC og på telefon'
          />
        </div>
      </div>
    </div>
  </>
);

export default MobileFriendly;
