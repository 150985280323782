import { Bod } from './Bod';
import { Organisasjonsform } from './Boligselskap';
import { Parkeringsplass } from './Parkeringsplass';
import { IdType } from './common';
import { t } from 'i18next';

export type Bolignr = number | string;
export type Leilighetsnr = string;
export type HNr = string;

// If renaming fields here, fix hardcoded field names in beboer/bolig search as well
export interface Bolig {
  boder: Bod[];
  boligadresseId?: number;
  bolignr?: Bolignr;
  boligselskapId: IdType;
  eierBrok: string;
  gateadresse?: string;
  hnr?: HNr;
  id: IdType;
  identifier?: string;
  inEnBrok?: string;
  inFireBrok?: string;
  inToBrok?: string;
  inTreBrok?: string;
  leilighetsnr?: Leilighetsnr;
  notat?: string;
  organisasjonsform: Organisasjonsform;
  parkeringsplasser: Parkeringsplass[];
  postnummer?: string;
  poststed?: string;
  seksjoneringsBrok?: string;
  type: 'BOLIG' | 'NAERING';
}

export interface BoligTag {
  name: string;
  protected: boolean;
}

// noinspection JSUnusedGlobalSymbols
export enum BoligState {
  CHECKED_IN = 'CHECKED_IN',
  EXPLOITATION = 'EXPLOITATION',
  SQUATTER = 'SQUATTER',
  ILLEGAL_USE = 'ILLEGAL_USE',
  SUSPICIOUS_ACTIVITY = 'SUSPICIOUS_ACTIVITY',
  EMPTY = 'EMPTY',
}

export const boligDisplayText = (bolig: Bolig, leilighetstype: string) =>
  (bolig.bolignr ? leilighetstype + ' ' + bolig.bolignr : '') +
  (bolig.bolignr && (bolig.gateadresse || bolig.hnr) ? ' - ' : '') +
  (bolig.gateadresse ? bolig.gateadresse : '') +
  (bolig.gateadresse && bolig.hnr ? ', ' : '') +
  (bolig.hnr ? bolig.hnr : '');

export const parkeringDisplayText = (parkeringsplass: Parkeringsplass) =>
  `${t('residentsAndHousing.parkingSpace_one')} ${parkeringsplass.identifikasjon}`;

export const bodDisplayText = (bod: Bod) =>
  `${t('residentsAndHousing.storageRoom_one')} ${bod.identifikasjon}`;

export const byBolignr = () => (a: Bolig, b: Bolig) =>
  (a.bolignr || '') > (b.bolignr || '') ? 1 : (b.bolignr || '') > (a.bolignr || '') ? -1 : 0;

export const boligOrderComparator = (a: Bolig, b: Bolig) =>
  (a.bolignr || 0) > (b.bolignr || 0) ? 1 : -1;
