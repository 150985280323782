import { Dayjs } from 'dayjs';

export const withTime = (date: Dayjs, time: string) =>
  date
    .set('hour', parseInt(time.toString().split(':')[0]))
    .set('minute', parseInt(time.toString().split(':')[1]))
    .set('seconds', 0);

export const minutes = (minutes: number) => seconds(60) * minutes;

export const seconds = (seconds: number) => 1000 * seconds;

export const findDiffRoundToHalfHour = (currentSlutt: Dayjs, prevStart: Dayjs) => {
  const diff = currentSlutt.diff(prevStart, 'minutes');
  const positiveDiff = diff < 0 ? diff * -1 : diff;
  const remainder = positiveDiff % 30 === 0 ? 0 : 30 - (positiveDiff % 30);

  return positiveDiff + remainder;
};
