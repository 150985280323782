import React, { lazy, useState } from 'react';
import { Link } from 'react-router-dom';

import Styremote48 from '../../images/48w/styremote4.webp';
import Styremote320 from '../../images/320w/styremote4.webp';
import Styremote640 from '../../images/640w/styremote4.webp';
import Styremote980 from '../../images/980w/styremote4.webp';
import lazyRetry from '../../utils/lazyRetry';
import ProgressiveImage from '../common/ProgressiveImage';

const TrialSignup = lazy(() => lazyRetry(() => import('../common/TrialSignup')));

export const CTASimple = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible && (
        <TrialSignup modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
      <div className='bg-white'>
        <div className='mx-auto max-w-7xl px-4 py-12 text-center sm:px-6 lg:px-8 lg:py-16'>
          <h2 className='text-3xl font-extrabold tracking-tight sm:text-4xl'>
            <span className='block'>Klar for å stupe i det?</span>
            <span className='block'>Sett i gang i dag.</span>
          </h2>
          <div className='mt-8 flex justify-center'>
            <div className='inline-flex rounded-md shadow'>
              <button
                data-cy={'features-try-free-button'}
                onClick={() => setModalVisible(true)}
                className='focus:focus-ring inline-flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700'
              >
                Bruk gratis
              </button>
            </div>
            <div className='ml-3 inline-flex'>
              <Link
                to='/priser'
                className='focus:focus-ring inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-5 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200'
              >
                Bestill
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const CTAOnlyButtons = () => {
  const [modalVisible, setModalVisible] = useState(false);
  return (
    <>
      {modalVisible && (
        <TrialSignup modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
      <div className='bg-white'>
        <div className='mx-auto max-w-7xl px-4 py-12 text-center sm:px-6 lg:px-8 lg:py-16'>
          <div className='mt-8 flex justify-center'>
            <div className='inline-flex rounded-md shadow'>
              <span
                onClick={() => setModalVisible(true)}
                className='inline-flex cursor-pointer items-center justify-center rounded-md border border-transparent bg-indigo-600 px-5 py-3 text-base font-medium text-white hover:bg-indigo-700'
              >
                Prøv gratis
              </span>
            </div>
            <div className='ml-3 inline-flex'>
              <Link
                to='/priser'
                className='inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-100 px-5 py-3 text-base font-medium text-indigo-700 hover:bg-indigo-200'
              >
                Bestill
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export const CTAWithFeature = () => {
  const [modalVisible, setModalVisible] = useState(false);

  return (
    <>
      {modalVisible && (
        <TrialSignup modalVisible={modalVisible} setModalVisible={setModalVisible} />
      )}
      <div className='bg-white'>
        <div className='mx-auto max-w-7xl px-4 py-16 sm:px-6 lg:px-8'>
          <div className='overflow-hidden rounded-lg bg-emerald-700 shadow-xl lg:grid lg:grid-cols-2 lg:gap-4'>
            <div className='px-6 pb-12 pt-10 sm:px-16 sm:pt-16 lg:py-16 lg:pr-0 xl:p-20'>
              <div className='lg:self-center'>
                <h2 className='text-3xl font-extrabold text-white sm:text-4xl'>
                  <span className='block'>Klar for enklere styrearbeid?</span>
                  <span className='block'>Ta i bruk Solibo Home helt gratis.</span>
                </h2>
                <p className='mt-4 text-lg leading-6 text-emerald-200'>
                  Vi tilbyr en gratis plan av Solibo Home. Alt du trenger av funksjonalitet. Ingen
                  forpliktelser.
                </p>
                <span
                  onClick={() => setModalVisible(true)}
                  className='mt-8 inline-flex cursor-pointer items-center rounded-md border border-transparent bg-white px-5 py-3 text-base font-medium text-emerald-600 shadow hover:bg-indigo-50'
                >
                  Bruk gratis
                </span>
              </div>
            </div>
            <div className='aspect-h-3 aspect-w-5 mx-2 -mt-6 rounded md:aspect-h-1 md:aspect-w-2 md:mx-6'>
              <ProgressiveImage
                placeholderSrc={Styremote48}
                src={Styremote980}
                srcSet={`${Styremote320} 320w, ${Styremote640} 640w, ${Styremote980} 980w`}
                sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
                alt='Illustrasjon av kommende møter i Solibo Home'
                fit={'object-none md:object-cover object-left-top md:h-5/6'}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
