import { Transition } from '@headlessui/react';
import React, { Fragment, useState } from 'react';
import { useTimeoutFn } from 'react-use';

import RanminderSingh48 from '../../images/48w/RanminderSingh.webp';
import SimenMalerbakken48 from '../../images/48w/SimenMalerbakken.webp';
import RanminderSingh320 from '../../images/320w/RanminderSingh.webp';
import SimenMalerbakken320 from '../../images/320w/SimenMalerbakken.webp';
import RanminderSingh640 from '../../images/640w/RanminderSingh.webp';
import SimenMalerbakken640 from '../../images/640w/SimenMalerbakken.webp';
import RanminderSingh980 from '../../images/980w/RanminderSingh.webp';
import SimenMalerbakken980 from '../../images/980w/SimenMalerbakken.webp';
import ProgressiveImage from '../common/ProgressiveImage';
import classNames from '../../utils/classNames';

interface Referanse {
  navn: string;
  tittel: string;
  referansetekst: string;
  bildenavn: string;
  image: {
    w48: string;
    w320: string;
    w640: string;
    w980: string;
  };
}

const referanser: Referanse[] = [
  {
    navn: 'Simen Malerbakken',
    tittel: 'Styreleder i flere sameier og borettslag',
    referansetekst:
      'Solibo Home er en svært intuitiv, rask og enkel styreportal. Helt siden vi tok portalen i bruk har utviklingen av portalen vært meget rask. Alle tilbakemeldinger vi kommer med blir tatt på alvor og det tar sjeldent lang tid før endringer i portalen blir synlige for oss brukere. Teamet bak Solibo Home sin evne til å lytte til brukernes tilbakemeldinger er virkelig et eksempel til etterfølgelse.',
    bildenavn: 'SimenMalerbakken',
    image: {
      w48: SimenMalerbakken48,
      w320: SimenMalerbakken320,
      w640: SimenMalerbakken640,
      w980: SimenMalerbakken980,
    },
  },
  {
    navn: 'Ranminder Singh',
    tittel: 'Styreleder i Boligsameiet Nordregate 18 og Sinsenterrassen 23-25-27 S/E',
    referansetekst:
      'Solibo Home er en meget ryddig og profesjonell styreportal som forenkler og effektiviserer informasjonsflyten mellom styret, beboere og forretningsfører. Den er også meget brukervennlig og lett for enhver å bruke uavhengig av teknisk innsikt og kompetanse.',
    bildenavn: 'SimenMalerbakken',
    image: {
      w48: RanminderSingh48,
      w320: RanminderSingh320,
      w640: RanminderSingh640,
      w980: RanminderSingh980,
    },
  },
];

export const Testimonial = () => {
  const [referanse, setReferanse] = useState<Referanse>(referanser[0]);
  const [active, setActive] = useState(0);
  const [, cancel, reset] = useTimeoutFn(() => {
    const index = referanser.indexOf(referanse);

    if (index === referanser.length - 1) {
      setActive(0);
      setReferanse(referanser[0]);
    } else {
      setActive(index + 1);
      setReferanse(referanser[index + 1]);
    }

    reset();
  }, 5000);

  const handleSetClick = (index: number) => {
    setReferanse(referanser[index]);
    setActive(index);
    cancel();
  };

  return (
    <div className='bg-white pt-16 lg:py-24'>
      <div className='relative bg-indigo-600 pb-16 lg:z-10 lg:pb-0'>
        <div className='absolute bottom-5 flex w-full justify-center space-x-5 sm:space-x-3'>
          {referanser.map((_, index) => (
            <div
              onClick={(_) => handleSetClick(index)}
              key={index}
              className={
                active === index
                  ? 'size-5 animate-bounce cursor-pointer rounded-full bg-indigo-100 hover:bg-indigo-300'
                  : 'size-5 cursor-pointer rounded-full bg-indigo-500 hover:bg-indigo-300'
              }
            />
          ))}
        </div>
        <div className='lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-3 lg:gap-8 lg:px-8'>
          <div className={classNames(active === 0 ? 'my-8' : '-my-8', 'relative')}>
            <div aria-hidden='true' className='absolute inset-x-0 top-0 h-1/2 bg-white lg:hidden' />
            <div className='mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:p-0'>
              <Transition
                as={Fragment}
                show={true}
                enter='transform transition duration-[400ms]'
                enterFrom='opacity-0 rotate-[-120deg] scale-50'
                enterTo='opacity-100 rotate-0 scale-100'
                leave='transform duration-200 transition ease-in-out'
                leaveFrom='opacity-100 rotate-0 scale-100 '
                leaveTo='opacity-0 scale-95 '
              >
                <div className='aspect-h-10 aspect-w-10 overflow-hidden rounded-xl sm:aspect-h-10 sm:aspect-w-16 lg:aspect-none lg:h-full'>
                  <ProgressiveImage
                    placeholderSrc={referanse.image.w48}
                    src={referanse.image.w980}
                    srcSet={`${referanse.image.w320} 320w, ${referanse.image.w640} 640w, ${referanse.image.w980} 980w`}
                    sizes='(min-width: 600px) 320w, (min-width: 800px) 640w, 980w'
                    alt='Styreleder Simen Malerbakken'
                    fit={'object-cover h-full max-w-[380px] object-top sm:h-5/6 shadow-lg'}
                  />
                </div>
              </Transition>
            </div>
          </div>
          <div className='mt-12 lg:col-span-2 lg:m-0 lg:pl-8'>
            <div className='mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20'>
              <div className='h-96 lg:h-80'>
                <Transition
                  as={Fragment}
                  show={true}
                  enter='transform transition duration-[400ms]'
                  enterFrom='opacity-0 rotate-[-120deg] scale-50'
                  enterTo='opacity-100 rotate-0 scale-100'
                  leave='transform duration-200 transition ease-in-out'
                  leaveFrom='opacity-100 rotate-0 scale-100 '
                  leaveTo='opacity-0 scale-95 '
                >
                  <blockquote>
                    <div>
                      <svg
                        className='size-12 text-white opacity-25'
                        fill='currentColor'
                        viewBox='0 0 32 32'
                        aria-hidden='true'
                      >
                        <path d='M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z' />
                      </svg>
                      <p className='mt-6 text-base font-medium text-white sm:text-lg lg:text-xl'>
                        {referanse.referansetekst}
                      </p>
                    </div>
                    <footer className='mt-6'>
                      <p className='text-base font-medium text-white'>{referanse.navn}</p>
                      <p className='text-base font-medium text-indigo-100'>{referanse.tittel}</p>
                    </footer>
                  </blockquote>
                </Transition>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
