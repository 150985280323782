import { useQuery } from 'react-query';

import { User } from './domain';

import { minutes } from '../../../utils/dateTime';

import { fetchGet } from '../../common';

export const userQueryKey = 'user';

export const useUserQuery = () => {
  const userQry = useQuery<User>(userQueryKey, () => fetchGet('/api/user'), {
    staleTime: minutes(10),
  });

  return {
    ...userQry,
    user: userQry.data,
  };
};
