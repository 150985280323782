import { CheckCircleIcon, InformationCircleIcon, XCircleIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

import { Spinner } from '../common/Spinner';

export const NotificationContainer = ({
  closeToast,
  type,
  title,
  description,
}: {
  closeToast: any;
  type: 'info' | 'success' | 'error' | 'loading';
  title: string;
  description: string;
}) => {
  const { t } = useTranslation();
  let showCloseButton = true;
  if (type === 'loading') showCloseButton = false;
  return (
    <div
      data-cy={`toast-${type}`}
      className='pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-gray-200 lg:mt-2'
    >
      <div className='relative p-1'>
        <div className='flex items-start'>
          <div className='shrink-0'>
            {type === 'success' && (
              <CheckCircleIcon className='size-8 text-green-700' aria-hidden='true' />
            )}
            {type === 'error' && <XCircleIcon className='size-8 text-red-600' aria-hidden='true' />}
            {type === 'info' && (
              <InformationCircleIcon className='size-8 text-blue-600' aria-hidden='true' />
            )}
            {type === 'loading' && <Spinner />}
          </div>
          <div className='mr-4 w-0 flex-1 px-1 pt-1.5'>
            <p className='text-base font-semibold text-gray-700'>{title}</p>
            <p className='mt-1 text-gray-700'>{description}</p>
          </div>
          {showCloseButton && (
            <div className='absolute right-2 top-2 flex shrink-0 p-1'>
              <button
                className='inline-flex rounded-md bg-white text-gray-400 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2'
                onClick={closeToast}
              >
                <span className='sr-only'>{t('button.close')}</span>
                <XCircleIcon className='size-5' aria-hidden='true' />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
